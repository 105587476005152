import * as Yup from "yup"
import {
  INVALID_EMAIL,
  INVALID_MOBILE_NUMBER,
  INVALID_YEAR,
  REQUIRED_MESSAGE,
  yearNow,
} from "../../../services/validations"

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  sex: "",
  consentToPatientInfo: [],
  birthday: {
    month: {
      value: null,
    },
    date: {
      value: null,
    },
    year: "",
  },
}

export const sexOptions = ["Male", "Female", "Other"]

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  consentToPatientInfo: Yup.array().when("id", {
    is: id => !id,
    then: Yup.array().min(1, REQUIRED_MESSAGE),
  }),
  email: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]{9}$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  sex: Yup.string().required(REQUIRED_MESSAGE),
  birthday: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(1900, INVALID_YEAR)
      .max(yearNow, INVALID_YEAR)
      .required(REQUIRED_MESSAGE),
  }),
})
