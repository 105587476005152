import React from "react"
import { Router } from "@reach/router"

import DoctorRoute from "components/Auth/DoctorRoute"
import PatientForm from "components/Doctor/PatientForm"
import PrivateRoute from "components/Auth/PrivateRoute"
import EprescriptionForm from "components/Eprescription/EprescriptionForm"
import UploadPrescription from "components/Eprescription/UploadPrescription"

export default () => {
  return (
    <Router basepath="/issue-erx">
      <PrivateRoute
        path="/upload"
        seoTitle="Upload Prescription"
        component={UploadPrescription}
        title="Upload Prescription"
        subtitle=""
      />
      <PrivateRoute
        path="/prescription-details"
        seoTitle="Issue ePrescription"
        component={EprescriptionForm}
        title="Issue ePrescription"
        subtitle=""
        type="new"
      />
      <DoctorRoute
        path="/patient-details"
        seoTitle="Issue ePrescription"
        component={PatientForm}
        title="Issue ePrescription"
        subtitle="Please ensure that the patient information is correct for the secure forwarding of your ePrescription."
        type="add"
        nextRoute="/issue-erx/prescription-details"
        backRoute="/doctor"
      />
    </Router>
  )
}
