import React, { Fragment, useContext, useEffect, useState } from "react"
import { Form, Formik } from "formik"
import { Link, navigate } from "gatsby"

import Message from "elements/Message"
import FormDate from "elements/Form/FormDate"
import FormInput from "elements/Form/FormInput"
import FormRadio from "elements/Form/FormRadio"
import ActionButtons from "elements/ActionButtons"
import FormCheckbox from "elements/Form/FormCheckbox"
import { ProgramName } from "../../components/Elements/Brand"

import {
  initialValues,
  sexOptions,
  validationSchema,
} from "./utils/patientFormSchema"
import { patient, toast } from "../../context/AppReducer"
import { AppContext } from "../../context/AppContext"
import { getAuthUser } from "../Auth/services/authUser"
import {
  addPatientToDoctor,
  handlePatientUpdate,
  ADD_PATIENT,
} from "./services/patients"

const PatientForm = ({ type, nextRoute, backRoute }) => {
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ content: null })
  const { state, dispatch } = useContext(AppContext)

  let formInitialValues =
    type === ADD_PATIENT || !state?.patient?.id
      ? { ...initialValues }
      : state?.patient

  const handleSuccess = ({ patientData }) => {
    setLoading(false)
    dispatch({ type: patient.SAVE_PATIENT, payload: patientData })
    if (nextRoute) {
      dispatch({
        type: toast.SHOW_TOAST,
        payload: {
          message: "Successfully added patient.",
          color: "success",
        },
      })
      return navigate(nextRoute)
    }
    setMessage({
      color: "success",
      content: (
        <div>
          <p className="is-size-6 mb-1">Successfully added patient.</p>
          <Link to="/issue-erx/prescription-details" className="mt-2">
            Issue an ePrescription to {patientData?.firstName}{" "}
            {patientData?.lastName}
          </Link>
        </div>
      ),
    })
  }

  const handleError = () => {
    setMessage({
      color: "danger",
      content: "There was an error adding this patient.",
    })
    setLoading(false)
  }

  const handleAlreadyAdded = () => {
    setMessage({
      color: "warning",
      content: (
        <div className="has-text-black">
          <p className="is-size-6 mb-1">
            You have already issued an ePrescription to this patient.
          </p>
        </div>
      ),
    })
    setLoading(false)
  }

  const updateCallback = () => {
    dispatch({
      type: toast.SHOW_TOAST,
      payload: {
        message: "Patient successfully updated.",
        color: "success",
      },
    })
    navigate("/patients")
  }

  const handlePatientAdd = ({ values }) => {
    setMessage({ content: null })
    addPatientToDoctor({
      values,
      doctorUid: user.id,
      callbacks: {
        success: handleSuccess,
        error: handleError,
        alreadyAdded: handleAlreadyAdded,
      },
    })
  }

  const handleSubmit = values => {
    setLoading(true)
    type === ADD_PATIENT
      ? handlePatientAdd({ values })
      : handlePatientUpdate({ values, id: state?.patient?.id, updateCallback })
  }

  useEffect(() => {
    if (type === ADD_PATIENT) {
      let tempUser = getAuthUser()?.userData
      setUser(tempUser)
    } else if (!state?.patient?.id) navigate("/patients")
  }, [state, type])

  const consentToPatientInfo = (
    <span>
      By submitting my patient's info, I confirm that I have obtained consent to
      share their information with MedGrocer and EDUCARE Patient Support
      Solutions to enroll them in the <ProgramName />
    </span>
  )

  return (
    <Fragment>
      <Formik
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <FormInput
              name="firstName"
              label="First Name"
              isRequired
              placeholder="Juan"
            />
            <FormInput
              name="lastName"
              label="Last Name"
              isRequired
              placeholder="dela Cruz"
            />
            <FormInput
              name="email"
              type="email"
              label="Email"
              isRequired
              placeholder="juan@mail.com"
              disabled={type !== ADD_PATIENT}
            />
            <FormInput
              name="mobileNumber"
              label="Mobile Number"
              isRequired
              placeholder="09123456789"
            />
            <FormDate
              name="birthday"
              values={values.birthday}
              label="Birthday"
              isRequired
              helper="As an added security measure, your patients will need to enter their birthday to view their ePrescription."
            />
            <FormRadio
              name="sex"
              value={values.sex}
              options={sexOptions}
              isRequired
              title="Sex"
            />
            {type === ADD_PATIENT && (
              <FormCheckbox
                name="consentToPatientInfo"
                values={values.consentToPatientInfo}
                options={[consentToPatientInfo]}
                isRequired
              />
            )}
            {message.content && (
              <Message color={message.color}>{message.content}</Message>
            )}
            <ActionButtons
              submit={{ label: "Save", loading }}
              back={{ label: "Back", link: backRoute || "/patients" }}
            />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default PatientForm
